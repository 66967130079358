<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="email_is_submitted"
      transition="fade-transition"
      color="success"
      type="success"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      Successfully Sent!
    </v-alert>

    <v-row v-if="!pageIsLoading">
      <v-col>
        <v-row>
          <v-col>
            <h1>You selected {{ stg_tsk_hlp.title }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="text-h5"> Create Manual Entry </v-card-title>

              <v-card-subtitle>
                A simple process that allows you to add new information to a
                system or database. Whether you're entering data into a
                spreadsheet, a note-taking application, or any other software,
                follow the step-by-step instructions.
              </v-card-subtitle>

              <v-card-actions>
                <v-btn text @click="viewHelper"> Start Now </v-btn>
              </v-card-actions>
            </v-card>

            <v-card class="mt-5">
              <v-card-title class="text-h5"> Sending an Email </v-card-title>

              <v-card-subtitle>
                This is to be filled up by the user, a common way to collect
                information, feedback, or responses from individuals. This
                process is often used in surveys, questionnaires, application
                forms, and other data collection activities.
              </v-card-subtitle>

              <v-card-actions>
                <v-btn text @click="send_helper_link">
                  Send
                  <v-progress-circular
                    v-show="email_is_submitting"
                    class="ml-2"
                    color="primary"
                    indeterminate
                  />
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn title="Go back" @click="$router.go(-1)"> Back </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  props: [
    "kanban_type",
    "stage_item_id",
    "stage_objective_id",
    "stage_objective_task_id",
    "helper_id",
  ],

  data: () => ({
    email_is_submitting: false,
    email_is_submitted: false,
    pageIsLoading: true,
    pageIsError: false,
    stg_obj_tsk: {},
    stg_tsk_hlp: {},
  }),

  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },

    viewHelper() {
      if (this.kanban_type === "Lender on boarding") {
        const company_id = this.stg_obj_tsk.stg_obj.stg_itm.company.id;

        this.$router.push({
          path: `/dashboard/admin/form/${this.stg_tsk_hlp.component}/read/${company_id}/${this.$store.getters["Auth/getAuthUser"].id}`,
        });
      }

      if (this.kanban_type === "Deal") {
        const helper = this.stg_tsk_hlps.find(
          (item) => item.id === this.helper_id
        );
        console.log("this is still under development");
      }
    },

    async send_helper_link() {
      try {
        this.email_is_submitting = true;

        const company_id = this.stg_obj_tsk.stg_obj.stg_itm.company.id;

        const res = await API().post("api/form/create_form_access_link", {
          company_id: company_id,
          form_unique_id: this.stg_tsk_hlp.component,
        });
        if (res.status === 201) {
          console.log(res.data);

          this.email_is_submitted = true;
          this.email_is_submitting = false;

          setTimeout(() => {
            this.email_is_submitted = false;
          }, 2000);
        } else {
          console.log("form access link not created.");

          this.pageIsError = true;
          setTimeout(() => {
            this.pageIsError = false;
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async read_data() {
      try {
        // get stage objective task data
        const read_stg_obj_tsk = await API().get(
          `api/kanban/read_stage_objective_task/?stg_obj_tsk_id=${Number(
            this.stage_objective_task_id
          )}`
        );
        console.log(read_stg_obj_tsk.data);

        this.stg_obj_tsk = read_stg_obj_tsk.data;

        // get stage task helpers data

        const read_stg_tsk_hlp = await API().get(
          `api/kanban/read_stage_helper/?helper_id=${Number(this.helper_id)}`
        );

        console.log(read_stg_tsk_hlp.data);

        this.stg_tsk_hlp = read_stg_tsk_hlp.data;

        this.pageIsLoading = false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },
  },

  mounted() {
    this.read_data();
  },
};
</script>
